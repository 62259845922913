// import "bootstrap/dist/css/bootstrap.min.css";
import Autosuggest from 'react-autosuggest';
import { useState, useEffect } from 'react';
import callesXLSX from "./calles.xlsx";
import './Autocomplete.css';
const XLSX = require('xlsx');

function App({ id, getCalles, array }) {
  const [streets, setStreets] = useState([]);
  const [streets1, setStreets1] = useState([]);
  const [value, setValue] = useState("");
  const [selectStreet, setSelectStreet] = useState({});

  //***Lectura de archivo calles.xlsx*** 
  const readFile = async (file) => {
    const response = await fetch(file);
    const data = new Uint8Array(await response.arrayBuffer());
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setStreets(jsonData);
    setStreets1(jsonData);
  };

  useEffect(() => {
    readFile(callesXLSX);
  }, []);

  const onSuggestionsFetchRequested = ({ value }) => {
    setStreets(filtrarCalles(value));
  }

  const filtrarCalles = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const filtrado = streets1.filter(el => {
      const textoCompleto = el.calle
      if (textoCompleto.toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(inputValue)) {
        return el;
      };
    });
    return inputLength === 0 ? [] : filtrado;
  };

  const onSuggestionsClearRequested = () => {
    readFile(callesXLSX);
  }

  const getSuggestionValue = (suggestion) => {
    return `${suggestion.calle}`;
  }

  const renderSuggestion = (suggestion) => {
    if (selectStreet.calle !== value) {
      return (
        <div className='sugerencia' onClick={() => seleccionarCalle(suggestion)}>
          {`${suggestion.calle}`}
        </div>)
    }
  };

  const seleccionarCalle = (calle) => {
    setValue(calle.calle)
    setStreets([]);
    setSelectStreet(calle);
    getCalles({ ...array, [id]: calle.calle });
  };

  const onChange = (e, { newValue }) => {
    setValue(newValue);
    getCalles({ ...array, [id]: newValue });
  };

  const inputProps = {
    placeholder: "Ingrese calle",
    value,
    onChange
  };

  const eventEnter = (e) => {
    if (e.key === "enter") {
      var split = e.target.value;
      var calle = {
        calle: split[0].trim(),
      };
      seleccionarCalle(calle);
    };
  };

  return (
    <div>
      <Autosuggest
        suggestions={streets}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={eventEnter}
      />
    </div>
  );
};

export default App;
