
const barrios = [
    { value: null, label: "Selecciona una opción" },
    { value: "14 de Noviembre", label: "14 de Noviembre" },
    { value: "27 de Abril", label: "27 de Abril" },
    { value: "31 de Mayo", label: "31 de Mayo" },
    { value: "A.T.S.A - Sanidad", label: "A.T.S.A - Sanidad" },
    { value: "Alto Ceferino", label: "Alto Ceferino" },
    { value: "Andino", label: "Andino" },
    { value: "Asociacion de Fomento Alto Mendoza", label: "Asociacion de Fomento Alto Mendoza" },
    { value: "Balcones del Cerro Arco", label: "Balcones del Cerro Arco" },
    { value: "Cano", label: "Cano" },
    { value: "Cerro de la Gloria", label: "Cerro de la Gloria" },
    { value: "Champagnat", label: "Champagnat" },
    { value: "Cipolletti", label: "Cipolletti" },
    { value: "Colinas del Oeste", label: "Colinas del Oeste" },
    { value: "Condominio Alto Challao", label: "Condominio Alto Challao" },
    { value: "Condominio Corredor del Oeste", label: "Condominio Corredor del Oeste" },
    { value: "Condominio La Angostura", label: "Condominio La Angostura" },
    { value: "Condominio La Yaya", label: "Condominio La Yaya" },
    { value: "Condominio Piedras I", label: "Condominio Piedras I" },
    { value: "Conjunto Residencial Dalvian", label: "Conjunto Residencial Dalvian" },
    { value: "Consorcio Los Quince", label: "Consorcio Los Quince" },
    { value: "Cooperativa 25 de Febrero", label: "Cooperativa 25 de Febrero" },
    { value: "Cooperativa Integral Barrio San Martin", label: "Cooperativa Integral Barrio San Martin" },
    { value: "Cooperativa Libertador", label: "Cooperativa Libertador" },
    { value: "COVINVIT - Los Solares", label: "COVINVIT - Los Solares" },
    { value: "COVSE - Soberania  Nacional", label: "COVSE - Soberania  Nacional" },
    { value: "Difunta Correa", label: "Difunta Correa" },
    { value: "El Eden", label: "El Eden" },
    { value: "El Libertador", label: "El Libertador" },
    { value: "El Progreso y Malvinas", label: "El Progreso y Malvinas" },
    { value: "El Triangulo", label: "El Triangulo" },
    { value: "Escorihuela", label: "Escorihuela" },
    { value: "Favorita Centro", label: "Favorita Centro" },
    { value: "Favorita Nueva", label: "Favorita Nueva" },
    { value: "Flores Oeste", label: "Flores Oeste" },
    { value: "Flores Sur", label: "Flores Sur" },
    { value: "Formaggia", label: "Formaggia" },
    { value: "Jardin Central", label: "Jardin Central" },
    { value: "Jardines de Karakorum", label: "Jardines de Karakorum" },
    { value: "Kilimanyaro", label: "Kilimanyaro" },
    { value: "La Ripiera", label: "La Ripiera" },
    { value: "Las Condes", label: "Las Condes" },
    { value: "Los Barrancos", label: "Los Barrancos" },
    { value: "Los Olivos", label: "Los Olivos" },
    { value: "Los Paraisos", label: "Los Paraisos" },
    { value: "Los Quince", label: "Los Quince" },
    { value: "Malargue", label: "Malargue" },
    { value: "Maristas", label: "Maristas" },
    { value: "Mirador del Cerro", label: "Mirador del Cerro" },
    { value: "Nueva Esperanza", label: "Nueva Esperanza" },
    { value: "Nueva Generacion", label: "Nueva Generacion" },
    { value: "Nuevas Quintas", label: "Nuevas Quintas" },
    { value: "Nuevo Amanecer", label: "Nuevo Amanecer" },
    { value: "Olivares", label: "Olivares" },
    { value: "Procrear", label: "Procrear" },
    { value: "Quintas de San Isidro", label: "Quintas de San Isidro" },
    { value: "Rene Favaloro", label: "Rene Favaloro" },
    { value: "San Agustin", label: "San Agustin" },
    { value: "San Francisco de Asis", label: "San Francisco de Asis" },
    { value: "Santa Rita", label: "Santa Rita" },
    { value: "Sector Consorcio", label: "Sector Consorcio" },
    { value: "Sierras Altas", label: "Sierras Altas" },
    { value: "Terrazas de Uno", label: "Terrazas de Uno" },
];



export default barrios;