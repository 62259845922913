import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import { Alert, Image } from "react-bootstrap";
import axios from "axios";
import { Map, Draggable } from "pigeon-maps";
import { Buffer } from "buffer";
import loading from "../formIncidentes/img/loading.gif";
import barrios from "../resources/barrios";
import Autocomplete from '../resources/Autocomplete';
const CryptoJS = require("crypto-js");

const tipoIncidente = [
  { id: 32590, name: "Arbolado" },
  { id: 32591, name: "Infraestructura" },
  { id: 32592, name: "Limpieza" },
  { id: 32593, name: "Tránsito" },
  { id: 32594, name: "Preventores" },
  { id: 33345, name: "Alumbrado" },
  { id: 33346, name: "Plazas y paseos" },
];

const tipoDeDocumento = [
  { value: 1, name: "DNI" },
  { value: 11, name: "CUIT" },
];

const schema = yup
  .object()
  .shape({
    altura: yup.string().required("Ingresar altura es obligatorio"),
    monoblock: yup.string(),
    piso: yup.string(),
    departamento: yup.string(),
    tipoDeIncidenteId: yup
      .number()
      .oneOf([32590, 32591, 32592, 32593, 32594, 33345, 33346], "Seleccione una opción válida")
      .required("Debe seleccionar una de las opciones"),
    barrio: yup.string(),
    manzana: yup.string(),
    casa: yup.string(),
    observaciones: yup
      .string()
      .required("Ingresar alguna observación es obligatorio")
      .min(30, "Se requiere un mínimo de 30 caracteres"),
    nombre: yup.string().required("Ingresar el Nombre es obligatorio"),
    apellido: yup.string().required("Ingresar el Apellido es obligatorio"),
    tipoDeDocumento: yup
      .number()
      .oneOf([1, 11], "Seleccione una opción válida")
      .required("Debe seleccionar una de las opciones"),
    nroDeDocumento: yup
      .string()
      .required("Ingresar un Nº de documento es obligatorio"),
    telefono: yup
      .number()
      .required("Ingresar el teléfono es obligatorio")
      .positive("El número de teléfono no puede ser negativo, verifique")
      .integer(
        "El número de teléfono no debe contener puntos ni comas, verifique"
      )
      .typeError("El teléfono debe ser un número, verifique"),
    correo: yup.string().email("Debe ser un correo válido, verifique"),
    fotos: yup.mixed(),
  })
  .required();

function FormularioPrueba() {
  //location
  const [geoStatus, setGeoStatus] = useState(null);
  const [usarUbicacion, setUsarUbicacion] = useState(true)
  const [coord, setCoord] = useState({ lat: -32.897845396235304, lng: -68.84326110818176 });
  const [center, setCenter] = useState([-32.897845396235304, -68.84326110818176]);
  const [regis, setRegis] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [escondida, setEscondida] = useState(true);
  const [mensaje, setMensaje] = useState("");
  const [result, setResult] = useState(null);
  const [fail, setFail] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showDataEdificio, setShowDataEdificio] = useState(false);
  const [showDataBarrio, setShowDataBarrio] = useState(false);
  const [showDataMap, setShowDataMap] = useState(false);
  const [calles, setCalles] = useState({ calle: "", entreCalle1: "", entreCalle2: "" });
  const [calleMessage, setCalleMessage] = useState("");
  const intervalRef = useRef();

  const getLocation = () => {
    if (!navigator.geolocation) {
      setGeoStatus('Tu explorador no tiene soporte para la ubicacion.')
    } else {
      setGeoStatus('Localizando...')
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeoStatus(null)
          setCoord({ lat: position.coords.latitude, lng: position.coords.longitude })
          setCenter([position.coords.latitude, position.coords.longitude])
        }
      )
    }
  }

  const changeLocationMethod = (e = null) => {
    if (e == null) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    } else {
      e.preventDefault()
      clearInterval(intervalRef.current)
      const refreshLocation = setInterval(() => {
        if (usarUbicacion) {
          getLocation()
        } else {
          setCoord({ lat: -32.897845396235304, lng: -68.84326110818176 })
          setCenter([-32.897845396235304, -68.84326110818176])
          clearInterval(refreshLocation)
        }
      }, 5000);
      intervalRef.current = refreshLocation;
    }
  }
  const [incidenteSelect, setIncidenteSelect] = useState(0);
  const [description, setDescription] = useState("");

  const handleDescription = ev => {
    let data = parseInt(ev.target.value);
    switch (data) {
      case 32590:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Con este tema podrás solicitar:<br />
          - Poda de ramas menores sobre techo, cables o que se encuentren bajas.<br />
          - Desrame de forestales que tapen luminarias, semáforos o carteles de tránsito.<br />
          - Desinfección de forestales.<br />
          Para verificaciones de forestales secos, inclinados o con enfermedad, o urgencias como
          ramas quebradas o forestales con riesgo inminente de caída, te pedimos comunicarte al 147
          o 0800-222-248323.<br />
          Para corte de ramas principales o gruesas, extracción/erradicación de forestal o corte de
          raíces que están dañando la vereda, te pedimos inciar el trámite por medio de una nota, enviando
          un mail a ventanillaunica@ciudaddemendoza.gov.ar</p>);
        break;
      case 32591:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Con este tema podrás informarnos:<br />
          - Solicitud de reparación de pozos sobre calles.<br />
          - Arreglos de rejillas rotas.<br />
          - Repintado de sendas peatonales, cordones o demarcado de bicisendas / ciclovías.<br />
          - Taludes (paredes de acequias) o cordones rotos.</p>);
        break;
      case 32592:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Con este tema podrás solicitar:<br />
          - Limpieza de cunetas.<br />
          - Limpieza de calzada.<br />
          - Retiro de escombros, remanentes o residuos varios (Se puede retirar hasta medio
          metro cúbico, unas 4 carretillas aproximadamente, por mes en una propiedad, si la cantidad es mayor
          puede ser notificado para el retiro privado).<br />
          - Vaciado de contenedores de residuos urbanos (no privados).<br />
          - Vaciado de puntos verdes o contenedores de puntos PET.</p>);
        break;
      case 32593:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Podrá dejarnos reclamos referidos a:<br />
          - Autos en estado de abandono.<br />
          - Falta de señalética vial.<br />
          - Denuncias por programa de Estacionamiento Medido.</p>);
        break;
      case 32594:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Podrá dejarnos consultas o pedidos por:<br />
          - Programa Alarmas Comunitarias.<br />
          - Programa Ojos En Alerta.<br />
          - Presencia de preventores por hechos de inseguridad (se solicita especificar).<br />
          - Solicitud de intervenciones por actividades prohibidas en el Art. 41 y 42 del Cód. de
          Convivencia.</p>);
        break;
      case 33345:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Con este tema podrá comunicarnos reclamos de:<br />
          - Luminarias y farolas de vereda apagadas, intermitentes o rotas.<br />
          - Cortes de iluminación de plazas y paseos de la ciudad.<br />
          - Problemas de postes de luminarias.<br />
          Le recordamos que si observa que las luminarias de toda una cuadra se encuentran
          apagadas, se comunique a EDEMSA a fin de que constaten algún desperfecto en
          el tendido eléctrico: (Teléfono de Contacto: 0800-333- 3672 / Opción 4 Reclamos por
          servicios en Alumbrado Público).</p>);
        break;
      case 33346:
        setDescription(<p style={{ color: "black", fontWeight: "bold", fontStyle: "italic" }}>Con este tema podrás comunicarnos reclamos, pedidos o consultas que hagan referencia a
          plazas, paseos y parques dentro de la Ciudad de Mendoza. Algunos ejemplos son:<br />
          - Mantenimiento y falta o exceso de riego de césped.<br />
          - Poda o riego de forestales.<br />
          - Limpieza de veredas o espacios verdes y jardines.<br />
          - Arreglo de mobiliario roto (cestos de basura, bancos, entre otros).<br />
          - Arreglo de elementos de patios de juegos o estaciones de salud.<br />
          Te pedimos que nos dejes el nombre del espacio verde afectado y/o la dirección concreta
          donde se encuentra el problema.</p>);
        break;
      default:
        setIncidenteSelect(data);
        setDescription("");
        break;
    };
  };

  const onSubmit = (data) => {
    const body = new FormData();
    setIsLoading(true);
    setDisabled(true);
    switch (data.tipoDeDocumento) {
      case 1:
        if (data.nroDeDocumento.length !== 8) {
          setMensaje("El DNI debe contener 8 caracteres");
          setEscondida(false);
          setError(true);
        } else {
          setError(false);
        };
        break;
      case 11:
        if (data.nroDeDocumento.length !== 11) {
          setMensaje("El CUIT debe contener 11 caracteres");
          setEscondida(false);
          setError(true);
        } else {
          setError(false);
        };
        break;
      default:
    };
    let i = 0;
    Array.from(data.fotos).forEach((file) => {
      if (
        (file.type !== "image/jpe") &&
        (file.type !== "image/jpg") &&
        (file.type !== "image/jpeg") &&
        (file.type !== "image/png")
      ) {
        setEscondida(false);
        setMensaje("Los formatos admitidos son .jpg, .jpe, .jpeg y .png");
      } else {
        body.append("request[" + i + "]", data.fotos[i]);
      }
      i++;
    });
    const formatDireccion = (data) => {
      if (showDataEdificio && calles.entreCalle1 && calles.entreCalle2 && showDataBarrio) {
        return data.altura + ", " + calles.calle + " - Monoblock: " + data.monoblock + " - Piso: " + data.piso + " - Depto: " + data.departamento +
          " - Entre calle " + calles.entreCalle1 + " & " + calles.entreCalle2 + " - Barrio: " + data.barrio + " - Manz: " + data.manzana + " - Casa: " + data.casa;
      } else if (showDataEdificio && calles.entreCalle1 && calles.entreCalle2 && !showDataBarrio) {
        data.barrio = "";
        return data.altura + ", " + calles.calle + " - Monoblock: " + data.monoblock + " - Piso: " + data.piso + " - Depto: " + data.departamento +
          " - Entre calle " + calles.entreCalle1 + " & " + calles.entreCalle2;
      } else if (!showDataEdificio && calles.entreCalle1 && calles.entreCalle2 && !showDataBarrio) {
        data.barrio = "";
        return data.altura + ", " + calles.calle + " - Entre calle " + calles.entreCalle1 + " & " + calles.entreCalle2;
      } else if (showDataEdificio && !calles.entreCalle1 && !calles.entreCalle2 && !showDataBarrio) {
        data.barrio = "";
        return data.altura + ", " + calles.calle + " - Monoblock: " + data.monoblock + " - Piso: " + data.piso + " - Depto: " + data.departamento;
      } else if (!showDataEdificio && calles.entreCalle1 && calles.entreCalle2 && showDataBarrio) {
        return data.altura + ", " + calles.calle + " - Entre calle " + calles.entreCalle1 + " & " + calles.entreCalle2 + " - Barrio: " +
          data.barrio + " - Manz: " + data.manzana + " - Casa: " + data.casa;
      } else if (!showDataEdificio && !calles.entreCalle1 && !calles.entreCalle2 && showDataBarrio) {
        return data.altura + ", " + calles.calle + " - Barrio: " + data.barrio + " - Manz: " + data.manzana + " - Casa: " + data.casa;
      } else if (showDataEdificio && !calles.entreCalle1 && !calles.entreCalle2 && showDataBarrio) {
        return data.altura + ", " + calles.calle + " - Monoblock: " + data.monoblock + " - Piso: " + data.piso + " - Depto: " + data.departamento +
          " - Barrio: " + data.barrio + " - Manz: " + data.manzana + " - Casa: " + data.casa;
      } else if (!showDataEdificio && data.entreCalle1 && data.entreCalle2 && !showDataBarrio) {
        return data.altura + " " + data.calle + " - Entre calle " + data.entreCalle1 + " & " + data.entreCalle2;
      } else {
        return data.altura + ", " + calles.calle;
      };
    };
    const incidente = {
      direccion: formatDireccion(data),
      areaServicioId: 5277,
      tipoIncidenteId: data.tipoDeIncidenteId,
      prioridadId: 2,
      localidad: "Prueba",
      barrio: data.barrio,
      latitud: coord.lat.toString(),
      longitud: coord.lng.toString(),
      observaciones: data.observaciones,
      pais: "Argentina",
      ciudadano: {
        nombre: data.nombre,
        apellido: data.apellido,
        dni: data.nroDeDocumento,
        email: data.correo,
        telefono: data.telefono.toString(),
        sexo: 1,
        tipoDocumentoId: 1,
      },
    };
    if (error === false) {
      body.append("incidente", JSON.stringify(incidente));
      setEscondida(true);
      cargarIncidente(body);
    };
  };

  const getHeaders = () => {
    const timestamp = Date.now().toString().substring(0, 10);
    // PRUEBA
    // const apiKey = "cKObEYcQwycBlMAKiXnS";
    // const secret = "HLqiUsvRUZUsCgwOuawwHLqiUsvRUZUsCgwOuaww";
    // PRODUCCIÓN
    const apiKey = "GGjmEsvAOHOwQucAKbFf";
    const secret = "TvlHOWDIlbGfRStMnlgpQfCkBjOrPTHiTFOsnHJa";
    const key = timestamp + "." + apiKey;
    const hash = CryptoJS.HmacSHA1(key, secret);
    const base64 = Buffer.from(hash.toString()).toString("base64");
    const authorization = "MD " + apiKey + ":" + base64;
    const axiosConfig = {
      headers: {
        "x-md-timestamp": parseInt(timestamp),
        "content-type": "multipart/form-data",
        Authorization: authorization,
      },
    };
    return axiosConfig;
  };

  const cargarIncidente = async (formData) => {
    await axios({
      method: "post",
      url: "/incidentes",
      data: formData,
      headers: getHeaders().headers,
    }).then((response) => {
      if (response.data.status === "ok") {
        setRegis(true);
        setFail(false);
        setResult(response.data.result);
      };
      if (response.data.status === "fail") {
        setRegis(false);
        setFail(true);
      };
      setIsLoading(false);
      setDisabled(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <MDBCard
              className=" text-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                background: "#B8DBE6",
                padding: 20,
              }}
            >
              <MDBCardBody>
                <MDBRow center>
                  <Image
                    src={require("./img/Logo-Ciudad.png")}
                    style={{ flex: 1, maxWidth: 400 }}
                  />
                </MDBRow>
                <h1
                  style={{
                    flex: 1,
                    textAlign: "center",
                    marginTop: 12,
                    color: "#000",
                  }}
                >
                  {" "}
                  Formulario para generación de incidentes{" "}
                </h1>

                <br />

                <div className="form-group">
                  <label htmlFor="tipoDeIncidenteId" style={{ color: "#000", fontWeight: "bold" }}>
                    Tipo de Incidente:
                    <label />
                  </label>
                  <select
                    name="func"
                    className="form-control"
                    onChangeCapture={ev => handleDescription(ev)}
                    {...register("tipoDeIncidenteId")}>
                    <option value={incidenteSelect}>Seleccione una opción</option>
                    {tipoIncidente.map(item => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                  {description ? <p>{description}</p> : null}
                  <p style={{ color: "red" }}>
                    {errors.tipoDeIncidenteId?.message}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="observaciones" style={{ color: "#000", fontWeight: "bold" }}>
                    Observaciones:{" "}
                  </label>
                  <textarea
                    className="form-control"
                    id="observaciones"
                    rows={3}
                    cols={163}
                    placeholder="Ingrese detalles del incidente"
                    minLength={30}
                    {...register("observaciones")}
                  />
                  <p style={{ color: "red" }}>
                    {errors.observaciones?.message}
                  </p>
                </div>

                <div className="form-group">
                  <p style={{ color: "#000", display: "inline", fontWeight: "bold" }}>Dirección del incidente</p>
                  <label htmlFor="calle" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                    Calle:{" "}
                  </label>
                  <div
                    className="form-group form-control"
                    style={{ width: "50%" }}>
                    <Autocomplete
                      id="calle"
                      getCalles={setCalles}
                      array={calles} />
                  </div>
                  {calleMessage
                    ? <p style={{ color: "red" }}>Ingresar calle es obligatorio</p>
                    : null}
                  <label htmlFor="altura" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                    Altura:{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="altura"
                    placeholder="Ingrese altura"
                    style={{ width: "50%" }}
                    {...register("altura")}
                  />
                  <p style={{ color: "red" }}>{errors.altura?.message}</p>

                  <div className="form-group">
                    <p style={{ color: "#000", display: "inline", fontWeight: "bold" }}>Entre que calles se ubica el incidente?</p>
                    <label htmlFor="entreCalle1" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                      Calle 1:{" "}
                    </label>
                    <div
                      className="form-group form-control"
                      style={{ width: "50%" }}>
                      <Autocomplete
                        id="entreCalle1"
                        getCalles={setCalles}
                        array={calles} />
                    </div>
                    <label htmlFor="entreCalle2" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                      Calle 2:{" "}
                    </label>
                    <div
                      className="form-group form-control"
                      style={{ width: "50%" }}>
                      <Autocomplete
                        id="entreCalle2"
                        getCalles={setCalles}
                        array={calles} />
                    </div>
                  </div>

                  <br />

                  <p style={{ color: "#000", display: "inline", fontWeight: "bold" }}>El incidente se ubica en un complejo/edificio?</p>

                  <div class="form-check">
                    <input
                      style={{ display: "inline" }}
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={showDataEdificio}
                      onClick={() => {
                        setShowDataEdificio(true);
                      }}
                    />
                    <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault1">
                      Si
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      style={{ display: "inline" }}
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={!showDataEdificio}
                      onClick={() => {
                        setShowDataEdificio(false);
                      }}
                    />
                    <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault2">
                      No
                    </label>
                  </div>

                  {showDataEdificio
                    ? <div className="form-group">
                      <label htmlFor="monoblock" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                        N° de monoblock:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="monoblock"
                        placeholder="Ingrese nº de monoblock"
                        style={{ display: "inline", width: "50%" }}
                        {...register("monoblock")}
                      />
                      <label htmlFor="piso" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                        N° de piso:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="piso"
                        placeholder="Ingrese nº de piso"
                        style={{ display: "inline", width: "50%" }}
                        {...register("piso")}
                      />
                      <label htmlFor="departamento" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                        N° de depto:{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="departamento"
                        placeholder="Ingrese nº de depto"
                        style={{ display: "inline", width: "50%" }}
                        {...register("departamento")}
                      />
                    </div>
                    : null}
                </div>

                <br />

                <p style={{ color: "#000", display: "inline", fontWeight: "bold" }}>El incidente se ubica en un barrio?</p>

                <div class="form-check">
                  <input
                    style={{ display: "inline" }}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={showDataBarrio}
                    onClick={() => {
                      setShowDataBarrio(true);
                    }}
                  />
                  <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault1">
                    Si
                  </label>
                </div>

                <div class="form-check">
                  <input
                    style={{ display: "inline" }}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={!showDataBarrio}
                    onClick={() => {
                      setShowDataBarrio(false);
                    }}
                  />
                  <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault2">
                    No
                  </label>
                </div>

                {showDataBarrio
                  ? <div className="form-group">
                    <label htmlFor="barrio" style={{ color: "#000", fontWeight: "bold" }}>
                      Barrio:
                    </label>
                    <select
                      id="barrio"
                      className="form-group form-control"
                      name="func"
                      {...register("barrio")}
                    >
                      {barrios.map(el => (
                        <option value={el.value} key={el.value}>{el.label}</option>
                      ))};
                    </select>

                    <label htmlFor="manzana" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                      Manzana:{" "}
                    </label>
                    <input
                      style={{ width: "50%" }}
                      type="text"
                      className="form-control"
                      id="manzana"
                      placeholder="Ingrese manzana"
                      {...register("manzana")} />
                    <label htmlFor="casa" style={{ display: "block", color: "#000", fontWeight: "bold" }}>
                      Casa:{" "}
                    </label>
                    <input
                      style={{ width: "50%" }}
                      type="text"
                      className="form-control"
                      id="casa"
                      placeholder="Ingrese casa"
                      {...register("casa")} />
                  </div>
                  : null}

                <br />

                <p style={{ color: "#000", display: "inline", fontWeight: "bold" }}>Desea ubicar el accidente en el mapa?</p>

                <div class="form-check">
                  <input
                    style={{ display: "inline" }}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={showDataMap}
                    onClick={() => {
                      setShowDataMap(true);
                    }}
                  />
                  <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault1">
                    Si
                  </label>
                </div>

                <div class="form-check">
                  <input
                    style={{ display: "inline" }}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={!showDataMap}
                    onClick={() => {
                      setShowDataMap(false);
                    }}
                  />
                  <label style={{ color: "#000", fontWeight: "bold" }} class="form-check-label" for="flexRadioDefault2">
                    No
                  </label>
                </div>

                <br />
                {showDataMap
                  ? <div>
                    <p style={{ color: "#000", fontSize: 16, fontWeight: "bold" }}>
                      Ubicá en el mapa el lugar del incidente arrastrando el
                      puntero hasta el sitio:
                    </p>
                    <div>
                      <Map
                        height={600}
                        center={center}
                      // maxZoom={20}
                      // minZoom={15}

                      >
                        <Draggable
                          anchor={[coord.lat, coord.lng]}
                          offset={[40, 40]}
                          onDragEnd={(anchor) => [
                            setCoord({ lat: anchor[0], lng: anchor[1] }),
                            setCenter([anchor[0], anchor[1]]),
                            changeLocationMethod()
                          ]}
                        >
                          <img
                            src={require("./img/ubicacion.png")}
                            width={64}
                            height={64}
                            alt="UBICACIÓN"
                          />
                        </Draggable>

                      </Map>
                      <div style={{ display: "flex" }}>
                        <button
                          type="submit"
                          className="btn btn-warning"
                          style={{ position: "inherit", top: 20, right: 20, color: "black" }}
                          onClick={(e) =>
                            [setUsarUbicacion(!usarUbicacion), changeLocationMethod(e)]
                          }
                        >
                          {!usarUbicacion ?
                            "Usar Mapa" : "Usar GPS"
                          }
                        </button>

                        {geoStatus !== null &&
                          <p style={{ position: 'inherit', color: "#000", marginTop: 5, marginLeft: 50 }}>{geoStatus}</p>
                        }
                      </div>
                    </div>
                  </div>
                  : null}

                <h1 style={{ textAlign: "center", color: "#000" }}>
                  Datos del Ciudadano
                </h1>

                <div className="form-group">
                  <label htmlFor="nombre" style={{ color: "#000", fontWeight: "bold" }}>
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    placeholder="Ingrese nombre"
                    {...register("nombre")}
                  />
                  <p style={{ color: "red" }}>{errors.nombre?.message}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="apellido" style={{ color: "#000", fontWeight: "bold" }}>
                    Apellido:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="apellido"
                    placeholder="Ingrese apellido"
                    {...register("apellido")}
                  />
                  <p style={{ color: "red" }}>{errors.apellido?.message}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="tipoDeDocumento" style={{ color: "#000", fontWeight: "bold" }}>
                    Tipo de documento:
                  </label>
                  <select
                    className="form-group form-control"
                    name="func"
                    {...register("tipoDeDocumento")}
                  >
                    <option value={0}>Seleccione una opción</option>
                    {tipoDeDocumento.map((el) => (
                      <option value={el.value}>{el.name}</option>
                    ))}
                  </select>
                  <p style={{ color: "red" }}>
                    {errors.tipoDeDocumento?.message}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="nroDeDocumento" style={{ color: "#000", fontWeight: "bold" }}>
                    Nº de documento:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nroDeDocumento"
                    placeholder="Ingrese nº de documento"
                    {...register("nroDeDocumento")}
                    maxLength={15}
                  />
                  <p style={{ color: "red" }}>
                    {errors.nroDeDocumento?.message}
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="telefono" style={{ color: "#000", fontWeight: "bold" }}>
                    Teléfono:
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="telefono"
                    {...register("telefono")}
                    placeholder="Ingrese teléfono"
                    maxLength={15}
                  />
                  <p style={{ color: "red" }}>{errors.telefono?.message}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="correo" style={{ color: "#000", fontWeight: "bold" }}>
                    Correo electrónico:{" "}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="correo"
                    placeholder="Ingrese correo electrónico"
                    {...register("correo")}
                  />
                  <p style={{ color: "red" }}>{errors.correo?.message}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="fotos" style={{ color: "#000", fontWeight: "bold" }}>
                    Adjunta aquí las fotos relacionadas al incidente:
                  </label>
                  <input
                    type="file"
                    multiple
                    className="form-control"
                    accept=".jpg, .jpeg"
                    id="foto"
                    {...register("fotos")}
                  />
                  <p style={{ color: "red" }}>{errors.fotos?.message}</p>
                </div>

                <Alert
                  key={"warning"}
                  variant={"warning"}
                  hidden={escondida}
                  style={{ color: "red" }}
                >
                  {mensaje}
                </Alert>

                <br />

                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      alt="loading"
                      src={loading}
                      style={{ width: "100px", height: "80px" }}
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      id="Submit"
                      className="btn btn-primary"
                      style={{ marginBottom: "20px", color: "black" }}
                      onClick={() => {
                        setRegis(false);
                        if (!calles.calle) {
                          setCalleMessage(true);
                        } else {
                          setCalleMessage(false);
                        };
                      }}
                      disabled={disabled}
                    >
                      ENVIAR
                    </button>
                  </div>
                )}

                {regis && (
                  <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Listo!</h4>
                    <p>
                      Incidente registrado correctamente con Número: {result}
                    </p>
                  </div>
                )}
                {fail && (
                  <div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading">Error!</h4>
                    <p>El incidente no pudo ser registrado</p>
                  </div>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
}

export default FormularioPrueba;

